<nav class="navbar navbar-expand-lg navbar-dark fixed-top bg-primary">
  <div class="container-fluid">
    <a class="navbar-brand" title="Adventure Works" [routerLink]="['../../']">
      <img
        alt="Adventure Works Logo"
        src="../../assets/adventure-works-logo-150x150.png"
        width="40px"
        height="40px"
        class="me-3" />
      Adventure Works
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
      (click)="toggleCollapsed()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      class="collapse navbar-collapse"
      id="navbarSupportedContent"
      [ngClass]="{ collapse: collapsed, 'navbar-collapse': true }">
      <ul class="navbar-nav ms-auto" *ngIf="loggedIn$ | async">
        <li
          class="nav-item mt-1"
          [ngClass]="{ active: router.url.startsWith('/customers') }">
          <a class="nav-link" [routerLink]="['/customers']">Customers</a>
        </li>
        <li
          class="nav-item mt-1"
          [ngClass]="{ active: router.url.startsWith('/products') }">
          <a class="nav-link" [routerLink]="['/products']">Products</a>
        </li>
        <li
          class="nav-item mt-1"
          [ngClass]="{ active: router.url.startsWith('/orders') }">
          <a class="nav-link" [routerLink]="['/orders']">Sale Orders</a>
        </li>
        <li
          class="nav-item mt-1"
          [ngClass]="{ active: router.url.startsWith('/prime-orders') }">
          <a class="nav-link" [routerLink]="['/prime-orders']">Prime Orders</a>
        </li>
        <li dropdown class="nav-item dropdown ms-4 bg-dark text-white rounded">
          <a
            dropdownToggle
            class="nav-link dropdown-toggle text-right px-2"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">
            <img
              [src]="profilePicture$ | async"
              alt="Profile Pic"
              class="pic me-1" />
            {{ email$ | async }}
          </a>
          <div
            *dropdownMenu
            class="dropdown-menu"
            aria-labelledby="navbarDropdown">
            <a class="dropdown-item border-top" [routerLink]="['/oidc/support']"
              >Support</a
            >
            <a class="dropdown-item" (click)="signoutRedirect()">Logout</a>
          </div>
        </li>
      </ul>
      <ul class="navbar-nav ms-auto" *ngIf="(identity$ | async) === undefined">
        <li class="nav-item">
          <a class="nav-link" (click)="signinRedirect()">Login</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
