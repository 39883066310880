/* istanbul ignore file */
/**
 * This file is generated by the openapi-ts-generator
 * #endpoints-ts.hbs
 * For issues or feature request, visit the repo: https://github.com/ikemtz/openapi-ts-generator
 * Do not edit.
 */
/* tslint:disable */
export enum Endpoints {
  customerAddresses = 'aw-odata/odata/v1/CustomerAddresses',
  customers = 'aw-odata/odata/v1/Customers',
  orderLineItems = 'aw-odata/odata/v1/OrderLineItems',
  orders = 'aw-odata/odata/v1/Orders',
  productCategories = 'aw-odata/odata/v1/ProductCategories',
  productModels = 'aw-odata/odata/v1/ProductModels',
  products = 'aw-odata/odata/v1/Products',
  salesAgents = 'aw-odata/odata/v1/SalesAgents',
}
export interface IEndpoints {
  readonly customerAddresses: string;
  readonly customers: string;
  readonly orderLineItems: string;
  readonly orders: string;
  readonly productCategories: string;
  readonly productModels: string;
  readonly products: string;
  readonly salesAgents: string;
}
