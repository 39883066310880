/* istanbul ignore file */
/**
 * This file is generated by the openapi-ts-generator
 * #endpoints-ts.hbs
 * For issues or feature request, visit the repo: https://github.com/ikemtz/openapi-ts-generator
 * Do not edit.
 */
/* tslint:disable */
export enum Endpoints {
  customers = 'aw-webapi/api/v1/Customers.json',
  orderLineItems = 'aw-webapi/api/v1/OrderLineItems.json',
  orders = 'aw-webapi/api/v1/Orders.json',
  products = 'aw-webapi/api/v1/Products.json',
}
export interface IEndpoints {
  readonly customers: string;
  readonly orderLineItems: string;
  readonly orders: string;
  readonly products: string;
}
